import * as React from 'react';
import { observer } from "mobx-react-lite";
import '../App.css';
import Uploader from './Dropzone/Uploader';
import DOEForm from './DOEForm/DOEForm';
import { Link } from "react-router-dom";

import ActionButtons from './ActionButtons/ActionButtons';
import DropzoneButton from './Dropzone/DropzoneButton';

  
function Home({ store }) {
  let stringifiedName = localStorage.getItem('okta-token-storage');
  let nameObject = JSON.parse(stringifiedName);
  nameObject= nameObject['idToken']['claims']['name']
  store.updateName(nameObject)
  // console.log("User")
  // console.log(nameObject)
   
return (
   <>
      <div className = "container">
        <div className="flex-parent-element">
          <div className="flex-child-element">
            <div className="dropzone-container">
              <div className='dropzone-button'>
                <DropzoneButton store = {store} buttonType = 'Discharge'/>
              </div>
              <Uploader store = {store} fileType = "Discharge"/>
            </div>
          </div>
          <div className="flex-child-element">
            <div className='dropzone-button'>
              <DropzoneButton store = {store} buttonType = 'Charging'/>
            </div>
            <Uploader store = {store} fileType = "Charging"/>
          </div>
          <div className="flex-child-element">
            <div className='dropzone-button'>
              <DropzoneButton store = {store} buttonType = 'NSB'/>
            </div>
            <Uploader store = {store} fileType = "NSB"/>
          </div>
        </div> 
      </div>
      <div className="form-container">
          <DOEForm store={store}/>
      </div>
      <div className="action-button-container">
        <ActionButtons store={store}/>
      </div>  
    </>
    )
}
export default observer(Home);