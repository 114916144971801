export const config = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID || '0oaip4zvnjU817m8d357',
  issuer: process.env.REACT_APP_OKTA_ISSUER || 'https://irobot.okta.com',
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI || window.location.origin + '/login/callback',
  postLogoutRedirectUri: process.env.REACT_APP_OKTA_POST_LOGOUT_REDIRECT_URI || window.location.origin,
  scopes: ['openid', 'profile', 'email', 'offline_access'],
  grantType: 'refresh_token',
  pkce: true,
}

