import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { observer } from "mobx-react-lite";
import Button from '@mui/material/Button';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 40,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontWeight: 600,
  fontSize: 16,
  border: "2px solid black"
}));
  
  function DOEHeader(paramaters) {
    let store = paramaters.state
    let type = 'isButtonActive'
  
    //Activate or Deactivate Station
    const handlestationButtonClick = (e) => {
      let stationNumber = e.target.name
      let updatedButtonState = !store.SerialNumbers[stationNumber][type]
      store.updateState(type, stationNumber, updatedButtonState)
    };

    let stations = []
    for (let i = 1; i <= 8; i++)
    {
      stations.push(i)
    }
    
    //Create Header Row
    const createrHeaderRow = stations.map((station) => {
      let stationNumber = "Station" + station
      let label = "Station " + station
      let isStationActive = store.SerialNumbers[stationNumber][type]
      
      //Alter Station button to appear enabled or disabled
      if (isStationActive)
        isStationActive = "contained"
      else
        isStationActive = "outlined"
      
      //If application is currently uploading files, all components are set to disabled
      let isDisabled = store.PostInsertionStatus["loading"]
  
    return (
      <Grid item sm={1} >
        <Button name={stationNumber} variant={isStationActive} onClick={handlestationButtonClick} disabled={isDisabled}>
          {label}
        </Button> 
      </Grid>
    )
  })
  
  return (
    <>
      <Grid item sm={2}>
      <Item>Station Number</Item>
      </Grid>
      {createrHeaderRow}
    </>
    );
  
  }

  export default observer(DOEHeader);
  