import { Routes, Route, useHistory } from "react-router-dom"
import LandingPage from "./component/LandingPage"
import Home from "./component/Home"
import DOEStore from "./DOEStore";
import Header from "./component/header";
import Login from './component/Custom Okta Login/LoginWidget'
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { config } from './component/Custom Okta Login/oktaConfig'

function App() {

async function getConfig(store)
{
  const axios = require('axios').default;
  
  
  const jsonData= require('./config.json'); 
  
  let API_DOE_trigger = jsonData["APIDOETriggerEndpoint"]
  let API_get_preassigned_url = jsonData["APIGetPreassignedURLEndpoint"]
  // console.log(API_get_preassigned_url)
  // console.log(API_DOE_trigger)

  // console.log("in front end")
  // console.log("Updated")
  
  store.updateConfig("doePipelineConnector_API", API_DOE_trigger)
  store.updateConfig("getPresignedFileUrl_API", API_get_preassigned_url)

}


  const history = useHistory()
  const oktaAuth = new OktaAuth(config)

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.push('/protected')
  }

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login')

  }

  const onAuthResume = async () => {
    history.push('/login')
  }


  const store = new DOEStore();
  getConfig(store)

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
      <Header/>
      <Route path="/" exact={true} component={LandingPage} />
      <Route path="/login" render={() => <Login />} />
      <Route path="/login/callback" render={props => <LoginCallback {...props} onAuthResume={onAuthResume} />}/>
      <SecureRoute path="/protected" exact={true} render={() =>  <Home store = {store}/> } />
    </Security>  
  )
}


export default App


// import './App.css';
// import React from 'react';
// import Uploader from './component/Dropzone/Uploader';
// import DOEForm from './component/DOEForm/DOEForm';
// import Header from "./component/header";
// import DOEStore from "./DOEStore";
// import ActionButtons from './component/ActionButtons/ActionButtons';
// import DropzoneButton from './component/Dropzone/DropzoneButton';
// import { LoginCallback, SecureRoute, Security } from '@okta/okta-react'
// import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
// import { BrowserRouter as Router, useNavigate } from 'react-router-dom'
// import { Box, Container, CssBaseline, ThemeProvider } from '@mui/material'
// import { config } from './component/Custom Okta Login/oktaConfig'

// async function getConfig(store)
// {
//   const axios = require('axios').default;
//   let API_ENDPOINT = "https://03s409fqba.execute-api.us-east-1.amazonaws.com/default/getAPIEndpoints"
 
//   const response = await axios.get(API_ENDPOINT);
//   console.log("Apis")
//   console.log(response["data"])

//   store.updateConfig("doePipelineConnector_API", response["data"]["doePipelineConnector"])
//   store.updateConfig("getPresignedFileUrl_API", response["data"]["getPresignedFileUrl"])


// }

// function App() {

//   const history = useNavigate()
//   const oktaAuth = new OktaAuth(config)

//   const restoreOriginalUri = async (_oktaAuth, originalUri) => {
//     history(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
//   }

//   const customAuthHandler = () => {
//     // Redirect to the /login page that has a CustomLoginComponent
//     history('/login')
//   }

//   const onAuthResume = async () => {
//     history('/login')
//   }

//   const store = new DOEStore();
//   getConfig(store)
 

 
//   // const axios = require('axios').default;
//   // const store = new DOEStore();

//   // const getAPIs = axios.get(API_ENDPOINT);

//   // console.log(getAPIs)
//   //const fields = getPresignedUploadParams["data"]["fields"]
//   //const uploadUrl = getPresignedUploadParams["data"]['url']

//   return (
//     <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
//       <Header/>
//       <Box mt={10}>
//         <Router path="/" exact={true} component={DOEForm} />
//       </Box>
//       {/* <div className = "container">
//         <div className="flex-parent-element">
//           <div className="flex-child-element">
//             <div className="dropzone-container">
//               <div className='dropzone-button'>
//                 <DropzoneButton store = {store} buttonType = 'Discharge'/>
//               </div>
//               <Uploader store = {store} fileType = "Discharge"/>
//             </div>
//           </div>
//           <div className="flex-child-element">
//             <div className='dropzone-button'>
//               <DropzoneButton store = {store} buttonType = 'Charging'/>
//             </div>
//             <Uploader store = {store} fileType = "Charging"/>
//           </div>
//           <div className="flex-child-element">
//             <div className='dropzone-button'>
//               <DropzoneButton store = {store} buttonType = 'NSB'/>
//             </div>
//             <Uploader store = {store} fileType = "NSB"/>
//           </div>
//         </div> 
//       </div>
//       <div className="form-container">
//           <DOEForm store={store}/>
//       </div>
//       <div className="action-button-container">
//         <ActionButtons store={store}/>
//       </div>  */}
//       {/* <Footer /> */}
//       </Security>
//   );
// }

// export default App;
