import { Button } from '@mui/material';
import React from 'react'
import { Link } from "react-router-dom";
import { useHistory, Redirect } from 'react-router-dom'

function LandingPage() {

const history = useHistory();
 
 const handleLogInClick = (e) => {
    history.push("/login")
};

    return (

    <div class="container-button">
        <div class="center-button">
            <Button variant="contained" size="large" style = {{minWidth: '300px', minHeight: '50px'}} onClick={handleLogInClick}>
                Click to Log In
            </Button>
        </div>
      </div>
    )
}

export default LandingPage