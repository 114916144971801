import * as React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { observer } from "mobx-react-lite";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 40,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontWeight: 600,
  fontSize: 16,
  border: "2px solid black"
}));



function DOERow(paramaters) {
  let store = paramaters.state

  //Update text field when new character is enetered
  const handleTextFieldChange = (e) => {
    console.log("e.target")
    console.log(e.target.name)
    console.log(e.target.value)
    let stationAndType = e.target.name.split("-")
    store.updateState(stationAndType[0], stationAndType[1], e.target.value)
    store.logStoreDetails()
  };

  let stations = []
  for (let i = 1; i <= 8; i++) {
    stations.push(i)
  }

  const createTextBoxRow = stations.map((station) => {

    //Logic to manipulate variable "paramaters.type". For example, change "Robot Serial" into Robot Serial-Station-1 & RobotSerialStation1. 
    //These different values are used for the TextBox ID, state ID, and texbox labels
    let label =  paramaters.type + "-" + "Station-" + station
    station = "Station" + station
    let stationNoSpaces = paramaters.type.replace(" ", "")
    let id = stationNoSpaces + "-" + station
    let isDisabled = !store.SerialNumbers[station]["isButtonActive"]
    let textValue = store.SerialNumbers[station][stationNoSpaces]

    //Find first active station
    let firstActiveStation = null
    for (let stationNumber in store.SerialNumbers) {
        if (store.SerialNumbers[stationNumber]["isButtonActive"]) {
            firstActiveStation = stationNumber
            break;
        }
        
    }

    //Reset Text Value if Station is not active
    if (isDisabled) {
      store.updateState(stationNoSpaces, station, "")
    }
    //Check to see if Apply All Switch is active and apply text from first station to all other active stations
    else if (station != firstActiveStation) {
      if (stationNoSpaces === "RobotFirmware" || stationNoSpaces === "DockFirmware") {
        if (store.Switches[stationNoSpaces]) {
          isDisabled = true
          textValue = store.SerialNumbers[firstActiveStation][stationNoSpaces]
          store.updateState(stationNoSpaces, station, textValue)
        }
      }
    }

    //If application is currently uploading files, all components are set to disabled
    isDisabled = isDisabled || store.PostInsertionStatus["loading"]

    return (
      <Grid item sm={1}>
        <TextField
          disabled={isDisabled}
          value={textValue}
          id={id}
          name={id}
          label={label}
          onChange={handleTextFieldChange} />
      </Grid>
    )
  })

  return (
    <>
      <Grid item sm={2}>
        <Item>{paramaters.type}</Item>
      </Grid>
      {createTextBoxRow}
    </>
  );
}

export default observer(DOERow);